// var roadline = document.getElementsByClassName('roadline')[0];
var crage = document.getElementsByClassName('roadline__img')[0];

window.onscroll = function () {
    // スクロールされたピクセル数
    var crage = document.getElementsByClassName('roadline__img')[0];
    var scroll = window.pageYOffset;
    var article = document.getElementsByClassName('l-content__main')[0];

    // スクロール範囲の最大のピクセル数
    var range = article.offsetHeight - document.documentElement.clientHeight;
    var top = scroll / range * 100
    if (top <= 100) {
        crage.style.top = top + '%';
    } else {
        crage.style.top = '100%';
    }

}
function copyToClipboard(button) {
    var copyTarget = button.previousElementSibling;
    copyTarget.select();
    document.execCommand("Copy");
    $('[data-toggle="popover"]').popover();
}
